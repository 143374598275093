import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import BackgroundImage from '../../components/BackgroundImage';
import { ImageOverlay } from '../../utils';
import { HTMLContent } from '../../components/Content';
import useSiteMetadata from '../../components/SiteMetadata';
import MetaComponent from '../../components/MetaComponent';

export const CarRentalsPageTemplate = ({ image, title, helmet, content }) => {
  return (
    <div>
      {helmet || ''}
      <BackgroundImage classes={'page-header-image'} imageInfo={image.image}>
        <h1 className='title page-title'>
          <hr />
          {title}
        </h1>
        <ImageOverlay overlay={image.overlay} />
      </BackgroundImage>
      <div className='page-container container content services-page-container'>
        <div className='columns'>
          <div className='column is-10 is-offset-1'>
            <HTMLContent content={content} className='blog-content' />
          </div>
        </div>
      </div>
    </div>
  );
};

const CarRentalsPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <CarRentalsPageTemplate
        location={location}
        title={frontmatter.title}
        image={frontmatter.heroImageObject}
        content={frontmatter.content}
        helmet={
          <MetaComponent
            title={`${frontmatter.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${frontmatter.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${frontmatter.heroImageObject.image}`}
          />
        }
      />
    </Layout>
  );
};

export default CarRentalsPage;

export const pageQuery = graphql`
  query carRentalsPageQueryZHHANS {
    markdownRemark(
      frontmatter: { templateKey: { eq: "car-rentals-page-zhhans" } }
    ) {
      frontmatter {
        title
        heroImageObject {
          overlay
          image
        }
        content
        seoObject {
          metaTitle
          metaDescription
        }
      }
    }
  }
`;
